@import "src/theme/breakpoints.scss";

ion-modal {
    $border-radius: 10px;

    $width-transition: width 0.15s ease-in-out;

    position: absolute;
    left: 100%;
    top: var(--space);
    width: var(--modal-width);
    height: calc(100vh - (var(--space) * 2));
    transform: translateX(calc(-100% - var(--space)));
    border-radius: 0 0 $border-radius $border-radius;

    --border-radius: 10px;
    --overflow: visible;

    transition: $width-transition;

    &::part(backdrop) {
        display: none;
    }

    &::part(content) {
        width: var(--modal-width);
        height: 100%;
        margin-top: calc(var(--layers-manager-height) + (var(--space) * 4));
        border-radius: var(--border-radius);

        transition: $width-transition;

        box-shadow: none;
    }

    &::part(handle) {
        display: none;
    }

    ion-content::part(scroll) {
        overflow-y: var(--overflow);
        overscroll-behavior-y: contain;
    }

    &.--is-full-height {
        &::part(content) {
            margin-top: 0;
        }
    }

    &.modal-sheet {
        ion-header {
            ion-toolbar {
                border-radius: $border-radius $border-radius 0 0;
                padding-right: var(--space);
                padding-left: var(--space);
            }
        }
    }

    &.modal-default {
        ion-header {
            ion-toolbar {
                border-radius: $border-radius $border-radius 0 0;
                padding-right: var(--space);
                padding-left: var(--space);
            }
        }
    }

    @media (max-width: $mobile-max-width) {
        width: var(--width);
        left: 100%;
        transform: translateX(-100%);
        height: inherit;
        top: inherit;

        &::part(content) {
            width: var(--width);
            box-shadow: 0 10px 15px 7px rgba(0, 0, 0, 0.3);
        }

        &::part(handle) {
            display: initial;
        }

        &.--is-full-height {
            top: initial;
            height: var(--height);
            border-radius: 0;

            &::part(content) {
                height: var(--height);
                box-shadow: none;
            }

            &.modal-sheet {
                ion-header {
                    ion-toolbar {
                        border-radius: 0;
                    }
                }
            }

            &.modal-default {
                ion-header {
                    ion-toolbar {
                        border-radius: 0;
                    }
                }
            }
        }
    }
}
