@font-face {
    font-family: "AppFont";
    src: url("/assets/fonts/SF-Pro-Display-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "AppFont";
    src: url("/assets/fonts/SF-Pro-Display-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
