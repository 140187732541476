ion-header {
    ion-title {
        text-align: left;
        padding-inline: calc(var(--space) * 2.5);
    }

    &.header-collapse-condense {
        ion-toolbar {
            --background: var(--ion-color-background);
            --border-style: none;

            background-color: var(--ion-color-background);

            ion-title {
                padding-inline: 0;
            }
        }
    }
}
