@import "src/theme/breakpoints.scss";

ion-app.ion-page {
    &.--divide-screen {
        display: grid;
        grid-template-columns: 1fr 1fr;

        ion-router-outlet {
            position: relative;

            ion-content {
                width: 350px;

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    @media (max-width: $mobile-max-width) {
        &.--divide-screen {
            display: flex;

            > :first-child {
                display: none;
            }

            ion-router-outlet {
                position: absolute;

                ion-content {
                    width: auto;

                    position: initial;
                    top: auto;
                    left: auto;
                    transform: none;
                }
            }
        }
    }
}
