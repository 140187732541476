@import "src/theme/mixins.scss";

ion-select {
    $border-width: 1px;

    --color: var(--ion-color-dark);
    --placeholder-color: var(--ion-color-dark-shade);

    &.--required {
        &::part(label) {
            &::after {
                content: "*";
                left: 2px;
                position: relative;
            }
        }
    }

    &::part(text),
    &::part(placeholder) {
        width: 50vw;
        padding: calc(var(--space) * 0.75) calc(var(--space) * 1.5) calc(var(--space) * 0.75) calc(var(--space) * 0.75);
    }

    &::part(container) {
        $height: 48px;

        height: $height;

        width: calc(100vw - ($border-width * 2));
        max-width: calc(100vw - ($border-width * 2));

        margin: 0;

        @include p1();
        color: var(--color);

        border-color: var(--ion-color-dark-shade);
        border-style: solid;
        border-width: $border-width;
        border-radius: 3px;

        transition:
            border-color ease-in 0.15s,
            color ease-in 0.15s;

        button {
            height: $height;
        }
    }

    &::part(icon) {
        position: absolute;
        bottom: calc((var(--space) * 0.75) + $border-width);
        right: calc(var(--space) * 0.5);
        height: 24px;
        width: 18px;
    }
}
