ion-popover {
    &::part(content) {
        --width: 300px;
    }

    &.layers-manager-popover {
        &::part(content) {
            --width: fit-content;
        }
    }

    &.datetime-modal {
        &::part(content) {
            --width: 350px;
        }
    }

    &.context-popover {
        &::part(content) {
            --width: 120px;
            border-radius: 4px;
        }
    }
}
