@import "src/theme/mixins.scss";

ion-input {
    &.sc-ion-input-ios-h {
        --color: var(--ion-color-dark);
        --placeholder-color: var(--ion-color-dark-shade);
        --padding-bottom: var(--space);

        &.--required {
            .label-text.sc-ion-input-ios::after {
                content: "*";
                left: 2px;
                position: relative;
            }
        }

        .input-wrapper.sc-ion-input-ios {
            .label-text-wrapper.sc-ion-input-ios {
                width: 100%;
                transform: none;

                .label-text.sc-ion-input-ios {
                    width: 100%;
                    margin-bottom: calc(var(--space) / 4);
                    color: var(--ion-color-dark-shade);
                    @include small();
                }
            }
            .native-wrapper.sc-ion-input-ios {
                .native-input.sc-ion-input-ios {
                    height: 48px;
                    @include p1();
                    color: var(--color);

                    padding: calc(var(--space) * 0.75);

                    border-color: var(--ion-color-dark-shade);
                    border-style: solid;
                    border-width: 1px;
                    border-radius: 3px;

                    margin: 0;

                    transition:
                        border-color ease-in 0.15s,
                        color ease-in 0.15s;
                }
            }
        }

        &.ion-invalid.ion-touched.ion-dirty {
            --color: var(--ion-color-danger);
            --placeholder-color: var(--ion-color-danger);

            .input-wrapper.sc-ion-input-ios {
                .label-text-wrapper.sc-ion-input-ios {
                    .label-text.sc-ion-input-ios {
                        color: var(--ion-color-danger);
                    }
                }
                .native-wrapper.sc-ion-input-ios {
                    .native-input.sc-ion-input-ios {
                        border-color: var(--ion-color-danger);
                    }
                }
            }
        }

        &[error-message]:not([error-message=""]) {
            &.ion-invalid.ion-touched.ion-dirty {
                &:after {
                    @include small();
                    $padding: calc(var(--space) * 0.75);
                    content: attr(error-message);
                    position: absolute;
                    z-index: 1;
                    left: calc(var(--space) * 0.75);
                    bottom: calc((var(--space) * -0.75) + var(--space));
                    background-color: var(--ion-color-danger);
                    color: var(--ion-color-danger-contrast);
                    padding: calc(var(--space) / 4) calc(var(--space) / 2);
                    max-width: 80%;
                    min-width: 30px;
                    border: 1px solid var(--ion-color-danger-contrast);
                    border-radius: 4px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
