ion-card {
    height: 73px;
    margin: 0;
    padding: var(--space);
    box-shadow: none;
    border: 1px solid transparent;
    border-radius: 10px;

    &.--hovered {
        border: 1px solid var(--ion-color-primary);
        @include card-shadow-effect();
        transform: translateY(calc(var(--space) / 8 * -1));
    }
}
