@mixin h1() {
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 2.2375rem;
    font-style: normal;
    text-decoration: none;
}

@mixin h2() {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2rem;
    font-style: normal;
    text-decoration: none;
}

@mixin h3() {
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 1.1rem;
    font-style: normal;
    text-decoration: none;
}

@mixin p1() {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4rem;
    font-style: normal;
    text-decoration: none;
}

@mixin small() {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.9rem;
    font-style: normal;
    text-decoration: none;
}

@mixin card-shadow-effect() {
    box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.075);
}

@mixin overlay-shadow() {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}

@mixin truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin truncate-text-multi-line($lines-to-show) {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines-to-show;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
