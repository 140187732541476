.map-icon {
    width: 15px !important;

    &.--is-blackspot{
        width: 30px !important;
    }

    &.--is-null{
        .icon-border{
            color:black;
            opacity: 1;
        }
        .icon-fill{
            color: var(--ion-color-medium);
            opacity: 1;
        }    
    }

    &.--is-1 {
        .icon-border{
            color:black;
            opacity: 0.8;
        }
        .icon-fill{
            color: var(--ion-color-success);
            opacity: 1;
        }
    }

    &.--is-2 {
        .icon-border{
            color:black;
            opacity: 1;
        }
        .icon-fill{
            color: var(--ion-color-warning);
            opacity: 1;
        }
    }

    &.--is-3 {
        .icon-border{
            color:black;
            opacity: 1;
        }
        .icon-fill{
            color: var(--ion-color-tertiary-tint);
            opacity: 1;
        }
    }

    &.--is-4 {
        .icon-border{
            color:black;
            opacity: 1;
        }
        .icon-fill{
            color: var(--ion-color-danger);
            opacity: 1;
        }
    }

    .--hovered &{
        display: none;
    }
}