:root {
    --ion-color-primary: #1d4780;
    --ion-color-primary-rgb: 29, 71, 128;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #1a3e71;
    --ion-color-primary-tint: #34598d;
    --ion-color-primary-background: rgba(29, 71, 128, 0.2);

    --ion-color-secondary: #0165bd;
    --ion-color-secondary-rgb: 1, 101, 189;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #0159a6;
    --ion-color-secondary-tint: #1a74c4;

    --ion-color-tertiary: #f58d17;
    --ion-color-tertiary-rgb: 197, 126, 20;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0, 0, 0;
    --ion-color-tertiary-shade: #d77b18;
    --ion-color-tertiary-tint: #f18715;
    --ion-color-tertiary-background: rgba(197, 126, 20, 0.3);

    --ion-color-success: #14c594;
    --ion-color-success-rgb: 20, 197, 148;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #12ad82;
    --ion-color-success-tint: #2ccb9f;
    --ion-color-success-background: rgba(20, 197, 148, 0.3);

    // --ion-color-warning: #e6b710;
    --ion-color-warning: #f3e852;
    --ion-color-warning-rgb: 230, 183, 16;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #caa10e;
    --ion-color-warning-tint: #e9be28;
    --ion-color-warning-background: rgba(230, 183, 16, 0.3);

    --ion-color-danger: #e73953;
    --ion-color-danger-rgb: 197, 20, 62;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #ad1237;
    --ion-color-danger-tint: #cb2c51;
    --ion-color-danger-background: rgba(197, 20, 62, 0.3);

    --ion-color-dark: #000000;
    --ion-color-dark-rgb: 0, 0, 0;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #868782;
    --ion-color-dark-tint: #1a1a1a;

    --ion-color-medium: #d9d9d9;
    --ion-color-medium-rgb: 217, 217, 217;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #bfbfbf;
    --ion-color-medium-tint: #dddddd;

    --ion-color-light: #ffffff;
    --ion-color-light-rgb: 255, 255, 255;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #e0e0e0;
    --ion-color-light-tint: #ffffff;

    --ion-color-background: #f7f7f7;

    --ion-font-family: "AppFont";

    // layout
    --space: 16px;

    --modal-width: 450px;
    --layers-manager-height: 48px;
    --interval-manager-width: 48px;
    --interval-manager-height: 48px;

    --MOBILE_MAX_WIDTH: 768px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    margin: 0;
}

.--h1 {
    @include h1();
    color: var(--ion-color-dark);
}

.--h2 {
    @include h2();
    color: var(--ion-color-dark);
}

.--h3 {
    @include h3();
    color: var(--ion-color-dark);
}

.--p1 {
    @include p1();
    color: var(--ion-color-dark);
}

.--small {
    @include small();
    color: var(--ion-color-dark);
}

.--bold {
    font-weight: bold;
}

.--straight__border {
    &__bottom {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    &__top {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }
}
