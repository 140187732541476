.map-card {
    position: relative;

    transform: translate(calc(-50% + 6px), calc(-50% + 6px));

    width: fit-content;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    gap: calc(var(--space) / 4);

    border-radius: 10px;
    background-color: var(--ion-color-background);
    padding: calc(var(--space) / 4) calc(var(--space) / 2);

    border: 2px solid black;

    &.--is-1 {
        background-color: var(--ion-color-success);
    }

    &.--is-2 {
        background-color: var(--ion-color-warning);
    }

    &.--is-3 {
        background-color: var(--ion-color-tertiary);
    }

    &.--is-4 {
        background-color: var(--ion-color-danger);
        div > p {
            color: white;
        }
    }

    &.--is-station {
        display: none;
    }

    &.--hidden {
        display: none;
    }

    &__circle {
        display: none;

        position: absolute;
        top: calc(var(--space) / 4);
        right: calc(var(--space) / 4);

        width: calc(var(--space) / 2);
        height: calc(var(--space) / 2);

        border-radius: 100%;

        &.--is-1 {
            background-color: var(--ion-color-success);
        }

        &.--is-2 {
            background-color: var(--ion-color-warning);
        }

        &.--is-3 {
            background-color: var(--ion-color-tertiary);
        }

        &.--is-4 {
            background-color: var(--ion-color-danger);
            div > p {
                color: white;
            }
        }
    }

    &__top {
        display: none;

        &__img {
            width: 14px;
        }

        &__text {
            white-space: nowrap;
            color: black;
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &__separator {
            display: none;
        }

        &__text {
            display: none;
            white-space: nowrap;
            color: black;

            &.--show {
                display: block;
            }

            &.--is-1 {
                color: var(--ion-color-success);
            }

            &.--is-2 {
                color: var(--ion-color-warning);
            }

            &.--is-3 {
                color: var(--ion-color-tertiary);
            }

            &.--is-4 {
                color: var(--ion-color-danger);
            }
        }
    }
    .--hovered & {
        &.--is-station {
            padding: calc(var(--space) / 2) calc(var(--space) * 2) calc(var(--space) / 2) calc(var(--space) / 2);
            display: flex;
        }

        &__circle {
            display: block;
        }

        &__top {
            display: flex;
            gap: calc(var(--space) / 2);
        }

        &__bottom {
            flex-direction: row;
            justify-content: flex-start;

            gap: calc(var(--space) / 4);

            &.--is-4 {
                p {
                    color: white;
                }
            }

            &__separator {
                color: var(--ion-color-dark-shade);

                &.--show {
                    display: block;
                }
            }

            &__text {
                color: black;
            }
        }
    }
    &.--hovered {
        padding: calc(var(--space) / 2) calc(var(--space) * 2) calc(var(--space) / 2) calc(var(--space) / 2);

        .map-card {
            &__circle {
                display: block;
            }

            &__top {
                display: flex;
                gap: calc(var(--space) / 2);
            }

            &__bottom {
                flex-direction: row;
                justify-content: flex-start;

                gap: calc(var(--space) / 4);

                &__separator {
                    color: var(--ion-color-dark-shade);

                    &.--show {
                        display: block;
                    }
                }

                &__text {
                    color: black;
                }
            }
        }
    }
}
