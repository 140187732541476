ion-back-button {
    &::part(icon) {
        $icon-size: 24px;
        width: $icon-size;
        height: $icon-size;

        margin-right: var(--space);
    }

    &::part(text) {
        color: var(--ion-color-primary);
    }
}
