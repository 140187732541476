ion-button {
    width: 100%;
    height: 52px;
    margin: 0;

    --border-radius: 50px;
    --padding-end: var(--space);
    --padding-start: var(--space);

    @include p1();

    &::part(native) {
        transition: background 0.3s ease;
    }
}
