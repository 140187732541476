// --ion-item-border-style

ion-item {
    overflow: visible;

    --padding-start: 0;
    --inner-padding-end: 0;
    --border-style: none;
    --background: transparent;
    --background-activated: transparent;
}
